@font-face {
  font-family: 'icomoon';
  src:  url('../../Fonts/icomoon.eot?fh0rbu');
  src:  url('../../Fonts/icomoon.eot?fh0rbu#iefix') format('embedded-opentype'),
    url('../../Fonts/icomoon.ttf?fh0rbu') format('truetype'),
    url('../../Fonts/icomoon.woff?fh0rbu') format('woff'),
    url('../../Fonts/icomoon.svg?fh0rbu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wallet:before {
  content: "\e900";
}
.icon-trekking:before {
  content: "\e901";
}
.icon-boarding-pass:before {
  content: "\e902";
}
.icon-train:before {
  content: "\e903";
}
.icon-binoculars:before {
  content: "\e904";
}
.icon-swimming-pool:before {
  content: "\e905";
}
.icon-sunset:before {
  content: "\e906";
}
.icon-sunglasses:before {
  content: "\e907";
}
.icon-suitcase:before {
  content: "\e908";
}
.icon-goggle:before {
  content: "\e909";
}
.icon-restaurant:before {
  content: "\e90a";
}
.icon-postcard:before {
  content: "\e90b";
}
.icon-adaptor:before {
  content: "\e90c";
}
.icon-photo:before {
  content: "\e90d";
}
.icon-passport:before {
  content: "\e90e";
}
.icon-navigation:before {
  content: "\e90f";
}
.icon-mountain:before {
  content: "\e910";
}
.icon-map-1:before {
  content: "\e911";
}
.icon-limousine:before {
  content: "\e912";
}
.icon-landmark:before {
  content: "\e913";
}
.icon-resort:before {
  content: "\e914";
}
.icon-sign:before {
  content: "\e915";
}
.icon-flip-flops:before {
  content: "\e916";
}
.icon-map:before {
  content: "\e917";
}
.icon-take-off:before {
  content: "\e918";
}
.icon-boat:before {
  content: "\e919";
}
.icon-compass:before {
  content: "\e91a";
}
.icon-cocktail-1:before {
  content: "\e91b";
}
.icon-cocktail:before {
  content: "\e91c";
}
.icon-credit-card:before {
  content: "\e91d";
}
.icon-camera:before {
  content: "\e91e";
}
.icon-calendar:before {
  content: "\e91f";
}
.icon-island:before {
  content: "\e920";
}
.icon-beach:before {
  content: "\e921";
}
.icon-around:before {
  content: "\e922";
}
.icon-plane:before {
  content: "\e923";
}