@import '../../../Assets/Scss/responsive';

/* :: 4.0 Header Area CSS */


/**
 * Overide Header Area
 */

.header-area {

  .logo {
    padding-top: 12px;
  }

  .palatin-main-menu .classy-navbar {
    transition-duration: 500ms;
    background-color: transparent;
    height: 120px;
    padding: 0;
  }


  .palatin-main-menu .classy-navbar .classynav ul li.active a {
    color: #ffffff;
    background-color: #a53e49;

    @media #{$breakpoint-xs} {
      height: auto;
    }
  }

  .bloco-pr2 {
    width: 220px;
    top: -6px;
    position: relative;
    left: 7px;
  }

  .bloco-uerj {
    width: 99px;
    bottom: 0;
    position: relative;
  }

  .bloco-ouvidoria {
    color: whitesmoke;
  }

  .bloco-inovuerj {
    width: 99px;
    bottom: 5px;
    position: relative;
    top: 1px;
  }

  @media only screen and (max-width: 993px) {
    .ouvidoria, .logo-pr2, .logo-uerj {
      display: none;
    }



  }

  // menu lateral
  @media only screen and (max-width: 767px) {

    .breakpoint-on .classy-navbar .classy-menu, .palatin-main-menu .classy-navbar .classynav ul li a {
      background-color: #860917;
    }

    .logo {
      padding-left: 35px;
    }

    .form-icones {
      visibility: hidden;
    }



  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {

    .breakpoint-on .classy-navbar .classy-menu, .palatin-main-menu .classy-navbar .classynav ul li a {
      background-color: #860917;
    }

    .logo {
      padding-left: 35px;
    }



  }

  @media only screen and (min-width: 993px) {
    .bloco-inovuerj {
      transform: scale(0.7);
    }
  }

  @media only screen and (min-width: 994px) and (max-width: 1438px) {
    .classynav li a {
      font-size: 12px !important;
    }
  }

  @media only screen and (min-width: 994px) and (max-width: 1282px) {
    .classynav li a {
      font-size: 10px !important;
    }
  }


}



