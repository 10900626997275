/* :: 5.0 Hero Area CSS */

/**
 * Overide HeroArea
 * Carrossel e fundo
 */

.single-hero-slide .hero-slides-content h2 {
  font-size: 3em;
}

// ocultar paginacao do carrossel
.owl-dots {
  display: none !important;
}

.owl-nav  {

  & {
    //position: relative;
  }

  .owl-prev, .owl-next {
    position: absolute;
    border: 1px solid white;
    color: white;
    background: rgb(146 13 40 / 36%);
    font-size: 10em;
    top: 36%; // -532px
    font-family: system-ui;
  }

  .owl-prev {
    padding: 2px 10px 10px 2px;
    border-left: 0px;
  }

  .owl-next {
    right:1px;
    padding: 2px 2px 10px 10px;
    border-right: 0px;
  }

  .owl-prev:hover, .owl-next:hover{
    background: repeating-radial-gradient(#920d28, transparent 400px);
  }

}




@media only screen and (min-width: 768px) and (max-width: 1199px) {

  .single-hero-slide .hero-slides-content h2 {
    font-size: 2.1em;
  }

}


// table e celulares
@media only screen and (max-width: 767px) {

  .single-hero-slide .hero-slides-content h2 {
    font-size: 2.1em;
  }

  .book-now-form {
    position: static;
  }

  .book-now-form form {
    /* display: flex; */
    display: block;
  }


}

// table e celulares
@media only screen and (max-width: 510px) {

  .single-hero-slide .hero-slides-content h2 {
    font-size: 1.7em;
  }

}







