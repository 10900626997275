@import '../../../Assets/Scss/responsive';

/**
 * Overide Seção de noticias
 */
.rooms-area {

  .news-list {
    margin-top: 10px;
  }

  .single-rooms-area .rooms-text {
    min-height: 197.56px;
  }

  .bg-img{
    border: 1px solid rgb(134 9 23 / 70%);
  }

  .single-rooms-area:hover .rooms-text {
    background: rgb(134 9 23 / 80%);
  }

  .single-rooms-area:hover .rooms-text .line {
    background: #f8f9fa;
  }





  /**
    Centralizar noticias
     */
  @media (min-width: 768px) {

    .col-md-6 {
      margin: 0 auto;
    }

  }

  
  @media #{$breakpoint-xs} {
    margin-top: 4em !important;
  }

}
