@import './theme_color';
@import './fonts';
@import './mixin';
@import './responsive';

/* [Master Stylesheet - v1.0] */

/* =========== Index of CSS ===========
:: 1.0 Import Fonts
:: 2.0 Import All CSS
:: 3.0 Base CSS
    :: 3.1.0 Spacing
    :: 3.2.0 Height
    :: 3.3.0 Section Padding
    :: 3.4.0 Section Heading
    :: 3.5.0 Preloader CSS
    :: 3.6.0 Miscellaneous
    :: 3.7.0 ScrollUp
    :: 3.8.0 Palatin Button
:: 4.0 Header Area CSS
:: 5.0 Hero Area CSS
:: 6.0 Book Now Area CSS
:: 7.0 About Us Area CSS
:: 8.0 Pool Area CSS
:: 9.0 Rooms Area CSS
:: 10.0 Services Area CSS
:: 11.0 Our Hotel Area CSS
:: 12.0 Testimonial Area CSS
:: 13.0 Footer Area CSS
:: 14.0 Breadcumb Area CSS
:: 15.0 Blog Area CSS
:: 16.0 Contact Area CSS
:: 17.0 Skills Area CSS
:: 18.0 Elements Area CSS
    :: 18.1.0 Single Cool Facts CSS
    :: 18.2.0 Accordians Area CSS
    :: 18.3.0 Tabs Area CSS
*/

/* :: 1.0 Import Fonts */

@font-face {
    font-family: 'FuturaLT-Book';
    src: url('../Fonts/FuturaLT-Book.eot?#iefix') format('embedded-opentype'), url('../Fonts/FuturaLT-Book.woff') format('woff'), url('../Fonts/FuturaLT-Book.ttf') format('truetype'), url('../Fonts/FuturaLT-Book.svg#FuturaLT-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* :: 2.0 Import All CSS */

//@import 'css/bootstrap.min.css';

// Override default variables before the import
$body-bg: #000;

// Import Bootstrap and its default variables
@import '~bootstrap/dist/css/bootstrap.css';
@import '../Others/Css/classy-nav.css';
@import '../Others/Css/owl.carousel.min.css';
@import '../Others/Css/animate.css';
@import '../Others/Css/magnific-popup.css';
@import '../Others/Css/font-awesome.min.css';
@import '../Others/Css/nice-select.css';
@import '../Others/Css/travel-icon.css';

/* :: 3.0 Base CSS */

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: $fonts;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
    line-height: 1.3;
    font-weight: 400;
}

p {
    color: $text-color;
    font-size: 14px;
    line-height: 2.1;
    font-weight: 400;
}

a,
a:hover,
a:focus {
    @include trans-duration(500ms);
    text-decoration: none;
    outline: 0 solid transparent;
    color: $dark-color;
    font-weight: 400;
    font-size: 14px;
}

ul,
ol {
    margin: 0;
    li {
        list-style: none;
    }
}

img {
    height: auto;
    max-width: 100%;
}

/* :: 3.1.0 Spacing */

.mt-15 {
    margin-top: 15px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

/* :: 3.2.0 Height */

.height-400 {
    height: 400px !important;
}

.height-500 {
    height: 500px !important;
}

.height-600 {
    height: 600px !important;
}

.height-700 {
    height: 700px !important;
}

.height-800 {
    height: 800px !important;
}

/* :: 3.3.0 Section Padding */

.section-padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section-padding-100-0 {
    padding-top: 100px;
    padding-bottom: 0;
}

.section-padding-0-100 {
    padding-top: 0;
    padding-bottom: 100px;
}

.section-padding-100-70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

/* :: 3.4.0 Section Heading */

.section-heading {
    position: relative;
    z-index: 1;
    margin-bottom: 60px;
    .line- {
        width: 90px;
        height: 2px;
        background-color: $pri-color;
        display: block;
        margin-bottom: 40px;
    }
    h2 {
        font-size: 48px;
        color: #860917; //$heading-color;
        @media #{$breakpoint-lg} {
            font-size: 40px;
        }
        @media #{$breakpoint-md} {
            font-size: 36px;
        }
        @media #{$breakpoint-xs} {
            font-size: 24px;
        }
    }
    p {
        margin-bottom: 0;
    }
    &.text-center {
        .line- {
            margin: 0 auto 40px;
        }
    }
    &.white {
        h2,
        p {
            color: $white-color;
        }
    }
}

/* :: 3.5.0 Preloader CSS */

.preloader {
    background-color: $white-color;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    .cssload-container {
        display: block;
        width: 97px;
    }
    .cssload-loading i {
        width: 19px;
        height: 19px;
        display: inline-block;
        border-radius: 50%;
        background: $pri-color;
    }
    .cssload-loading i:first-child {
        opacity: 0;
        animation: cssload-loading-ani2 0.58s linear infinite;
        -o-animation: cssload-loading-ani2 0.58s linear infinite;
        -ms-animation: cssload-loading-ani2 0.58s linear infinite;
        -webkit-animation: cssload-loading-ani2 0.58s linear infinite;
        -moz-animation: cssload-loading-ani2 0.58s linear infinite;
        transform: translate(-19px);
        -o-transform: translate(-19px);
        -ms-transform: translate(-19px);
        -webkit-transform: translate(-19px);
        -moz-transform: translate(-19px);
    }
    .cssload-loading i:nth-child(2),
    .cssload-loading i:nth-child(3) {
        animation: cssload-loading-ani3 0.58s linear infinite;
        -o-animation: cssload-loading-ani3 0.58s linear infinite;
        -ms-animation: cssload-loading-ani3 0.58s linear infinite;
        -webkit-animation: cssload-loading-ani3 0.58s linear infinite;
        -moz-animation: cssload-loading-ani3 0.58s linear infinite;
    }
    .cssload-loading i:last-child {
        animation: cssload-loading-ani1 0.58s linear infinite;
        -o-animation: cssload-loading-ani1 0.58s linear infinite;
        -ms-animation: cssload-loading-ani1 0.58s linear infinite;
        -webkit-animation: cssload-loading-ani1 0.58s linear infinite;
        -moz-animation: cssload-loading-ani1 0.58s linear infinite;
    }
    @keyframes cssload-loading-ani1 {
        100% {
            transform: translate(39px);
            opacity: 0;
        }
    }
    @-o-keyframes cssload-loading-ani1 {
        100% {
            -o-transform: translate(39px);
            opacity: 0;
        }
    }
    @-ms-keyframes cssload-loading-ani1 {
        100% {
            -ms-transform: translate(39px);
            opacity: 0;
        }
    }
    @-webkit-keyframes cssload-loading-ani1 {
        100% {
            -webkit-transform: translate(39px);
            opacity: 0;
        }
    }
    @-moz-keyframes cssload-loading-ani1 {
        100% {
            -moz-transform: translate(39px);
            opacity: 0;
        }
    }
    @keyframes cssload-loading-ani2 {
        100% {
            transform: translate(19px);
            opacity: 1;
        }
    }
    @-o-keyframes cssload-loading-ani2 {
        100% {
            -o-transform: translate(19px);
            opacity: 1;
        }
    }
    @-ms-keyframes cssload-loading-ani2 {
        100% {
            -ms-transform: translate(19px);
            opacity: 1;
        }
    }
    @-webkit-keyframes cssload-loading-ani2 {
        100% {
            -webkit-transform: translate(19px);
            opacity: 1;
        }
    }
    @-moz-keyframes cssload-loading-ani2 {
        100% {
            -moz-transform: translate(19px);
            opacity: 1;
        }
    }
    @keyframes cssload-loading-ani3 {
        100% {
            transform: translate(19px);
        }
    }
    @-o-keyframes cssload-loading-ani3 {
        100% {
            -o-transform: translate(19px);
        }
    }
    @-ms-keyframes cssload-loading-ani3 {
        100% {
            -ms-transform: translate(19px);
        }
    }
    @-webkit-keyframes cssload-loading-ani3 {
        100% {
            -webkit-transform: translate(19px);
        }
    }
    @-moz-keyframes cssload-loading-ani3 {
        100% {
            -moz-transform: translate(19px);
        }
    }
}

/* :: 3.6.0 Miscellaneous */

.bg-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-white {
    background-color: #ffffff !important;
}

.bg-dark {
    background-color: #000000 !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.font-bold {
    font-weight: 700;
}

.font-light {
    font-weight: 300;
}

.bg-overlay {
    position: relative;
    z-index: 2;
    background-position: center center;
    background-size: cover;
    &::after {
        background-color: rgba(0, 0, 0, 0.63);
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
    }
}

.bg-fixed {
    background-attachment: fixed !important;
}

/* :: 3.7.0 ScrollUp */

#scrollUp {
    background-color: $pri-color;
    border-radius: 0;
    bottom: 50px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    color: $white-color;
    font-size: 24px;
    height: 40px;
    line-height: 40px;
    right: 50px;
    text-align: center;
    width: 40px;
    @include trans-duration(500ms);
    &:hover,
    &:focus {
        background-color: $heading-color;
    }

    @media #{$breakpoint-xs} {
        bottom: 1em;
        right: 0.5em;
    }
}

/* :: 3.8.0 Palatin Button */

.palatin-btn {
    background-color: $pri-color;
    @include trans-duration(500ms);
    position: relative;
    z-index: 1;
    display: inline-block;
    min-width: 123px;
    height: 53px;
    color: $white-color;
    border: none;
    border-radius: 0;
    padding: 0 30px;
    font-size: 16px;
    line-height: 53px;
    text-transform: capitalize;
    &:hover,
    &:focus {
        font-size: 16px;
        background-color: $heading-color;
        color: $white-color;
    }
    &.btn-2 {
        background-color: $heading-color;
        color: $white-color;
        &:hover,
        &:focus {
            font-size: 16px;
            background-color: $pri-color;
            color: $white-color;
        }
    }
    &.btn-3 {
        background-color: $white-color;
        color: $pri-color;
        border: 2px solid $pri-color;
        height: 49px;
        line-height: 48px;
        &:hover,
        &:focus {
            font-size: 16px;
            background-color: $pri-color;
            color: $white-color;
        }
    }
}

/* :: 4.0 Header Area CSS */
.header-area {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;

    .palatin-main-menu {
        position: relative;
        width: 100%;
        background-color: rgb(0 0 0 / 83%); //background-color: rgba(0, 0, 0, 0.63);
        border-bottom: 2px solid rgba(134, 9, 23, 0.63); // 2px solid rgba(203, 134, 112, 0.63);
        .classy-nav-container {
            background-color: transparent;
        }

        .classy-navbar {
            @include trans-duration(300ms);
            background-color: transparent;
            height: 150px;
            padding: 0;
            // @media #{$breakpoint-xs} {
            //     height: 70px;
            // }

            .nav-brand {
                @media #{$breakpoint-lg} {
                    max-width: 140px;
                }
                @media #{$breakpoint-xs} {
                    max-width: 140px;
                }
            }

            .classynav ul li a {
                text-transform: capitalize;
                color: $white-color;
                font-size: 14px;
                background-color: transparent;

                &:hover,
                &:focus {
                    color: $white-color;
                    //background-color: $bg-default;
                    // background: linear-gradient(355deg, #ccc, #920d28);
                    background: repeating-radial-gradient(#920d28, transparent 400px);
                }

                @media #{$breakpoint-lg} {
                    font-size: 14px;
                }
                @media #{$breakpoint-md} {
                    background-color: $bg-default;
                }
                @media #{$breakpoint-xs} {
                    background-color: $bg-default;
                }
            }

            .classynav ul li.active a {
                color: $white-color;
                background-color: $bg-default;
            }

            .classynav ul li.megamenu-item > a::after,
            .classynav ul li.has-down > a::after {
                color: $white-color;
            }

            .classynav ul li ul li a {
                color: $heading-color;
                @media #{$breakpoint-lg} {
                    padding: 0 20px;
                }
                @media #{$breakpoint-md} {
                    color: $white-color;
                }
                @media #{$breakpoint-xs} {
                    color: $white-color;
                }
            }

            .menu-btn {
                margin-left: 100px;
                @media #{$breakpoint-lg} {
                    margin-left: 30px;
                }
                @media #{$breakpoint-md} {
                    margin-left: 30px;
                    border: 2px solid $white-color;
                    margin-top: 30px;
                    display: inline-block;
                }
                @media #{$breakpoint-xs} {
                    margin-left: 30px;
                    border: 2px solid $white-color;
                    margin-top: 30px;
                    display: inline-block;
                }

                a {
                    color: $white-color;
                    font-size: 16px;
                }
            }
        }
    }

    .is-sticky {
        .palatin-main-menu {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 9999;
            background-color: #000;
            box-shadow: 0 5px 50px 15px rgba(0, 0, 0, 0.2);

            .classy-navbar {
                height: 90px;
                // @media #{$breakpoint-xs} {
                //     height: 70px;
                // }
            }
        }
    }
}

.breakpoint-on .classy-navbar .classy-menu {
    @media #{$breakpoint-md} {
        background-color: $bg-default;
    }
    @media #{$breakpoint-xs} {
        background-color: $bg-default;
    }
}

.classynav ul li .megamenu .single-mega.cn-col-4 {
    @media #{$breakpoint-md} {
        padding: 0;
    }
    @media #{$breakpoint-xs} {
        padding: 0;
    }
}

.classycloseIcon .cross-wrap span {
    background: $white-color;
}


/* :: 5.0 Hero Area CSS */
.hero-slides {
    position: relative;
    z-index: 1;
    .owl-dots {
        @include display-flex(flex);
        position: absolute;
        bottom: 30px;
        left: 4%;
        @media #{$breakpoint-lg} {
            left: 1%;
        }
        @media #{$breakpoint-md} {
            left: 5px;
            @include flex-direction(column);
        }
        @media #{$breakpoint-xs} {
            bottom: 100px;
        }
        .owl-dot {
            @include trans-duration(300ms);
            border: 2px solid transparent;
            background-color: transparent;
            display: block;
            padding: 3px 5px;
            color: $white-color;
            font-size: 14px;
            &.active,
            &:hover,
            &:focus {
                color: $white-color;
                background-color: #1b3f4c;
                border: 2px solid $pri-color;
                box-shadow: none;
            }
        }
    }
}

.single-hero-slide {
    width: 100%;
    height: 950px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @media #{$breakpoint-lg} {
        height: 800px;
    }
    @media #{$breakpoint-md} {
        height: 750px;
    }
    @media #{$breakpoint-xs} {
        height: 750px;
    }
    .slide-img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -10;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .hero-slides-content {
        display: inline-block;
        padding: 60px 50px;
        background-color: rgba(0, 0, 0, 0.63);
        border: 2px solid $pri-color;
        text-align: center;
        @media #{$breakpoint-lg} {
            padding: 40px 20px;
        }
        @media #{$breakpoint-md} {
            padding: 30px 20px;
        }
        @media #{$breakpoint-xs} {
            padding: 20px;
        }
        .line {
            width: 90px;
            height: 2px;
            background-color: $pri-color;
            margin: 0 auto 30px;
        }
        h2 {
            position: relative;
            z-index: 1;
            font-size: 72px;
            color: $white-color;
            margin-bottom: 0;
            display: inline-block;
            @media #{$breakpoint-lg} {
                font-size: 48px;
            }
            @media #{$breakpoint-md} {
                font-size: 36px;
            }
            @media #{$breakpoint-xs} {
                font-size: 30px;
            }
        }
        p {
            font-size: 16px;
            color: $white-color;
        }
    }
}

.hero-slides .owl-item.active .single-hero-slide .slide-img {
    @include animation(slide 20s linear infinite);
}

@-webkit-keyframes slide {
    0% {
        @include transform(scale(1));
    }
    50% {
        @include transform(scale(1.2));
    }
    100% {
        @include transform(scale(1));
    }
}

@keyframes slide {
    0% {
        @include transform(scale(1));
    }
    50% {
        @include transform(scale(1.2));
    }
    100% {
        @include transform(scale(1));
    }
}


/* :: 6.0 Book Now Area CSS */

.book-now-area {
    position: relative;
    z-index: 100;
    //margin-bottom: 15px;
}

.book-now-form {
    position: relative;
    z-index: 1;
    top: -150px; // -87px
    left: -110px;
    form {
        @include display-flex(flex);
        @include align-items(center);
        @include flex-wrap(wrap);
        width: 100%;
        .form-group,
        button {
            @include display-flex(flex);
            @include align-items(center);
            @include justify-content-center;
            @include flex-direction(column);
            @include flex(0 0 20%);
            max-width: 20%;
            width: 20%;
            height: 174px;
            background-color: #860917; //background-color: $heading-color;
            border-left: 2px solid darkgray; //border-left: 2px solid $pri-color;
            border-top: 2px solid darkgray; //border-top: 2px solid $pri-color;
            border-bottom: 2px solid darkgray; //border-bottom: 2px solid $pri-color;
            margin-bottom: 0;
            padding: 0 30px;
            @media #{$breakpoint-md} {
                padding: 0 20px;
            }
            @media #{$breakpoint-xs} {
                @include flex(0 0 100%);
                max-width: 100%;
                width: 100%;
                height: 70px;
                // border-bottom: 0 solid transparent;
                border-right: 2px solid darkgray;
                margin-top: 12px;
            }
        }
        .form-group {
            cursor: pointer;
            label {
                color: rgb(255, 255, 255); //$pri-color;
                display: block;
                font-size: 14px;
                width: 100%;
                text-align: center;
                cursor: pointer;
            }
        }



        .form-group:hover {
            // background: linear-gradient(355deg, #ccc, #920d28);
            background: repeating-radial-gradient(#920d28, transparent 400px);
        }

        button {
            border-right: 2px solid $pri-color;
            font-size: 16px;
            background-color: $pri-color;
            text-transform: uppercase;
            color: $white-color;
            cursor: pointer;
            @include trans-duration(300ms);
            padding: 0;
            &:hover,
            &:focus {
                outline: none;
                background-color: $heading-color;
            }
        }
        .form-control:focus {
            box-shadow: none;
        }
    }
    .nice-select {
        background-color: transparent;
        border-radius: 0;
        border: none;
        font-size: 14px;
        height: auto;
        line-height: 1;
        padding-left: 0;
        padding-right: 30px;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        color: $white-color;
        &::after {
            border-bottom: 1px solid $white-color;
            border-right: 1px solid $white-color;
            height: 10px;
            margin-top: -4px;
            width: 10px;
        }
        .list {
            background-color: $white-color;
            border-radius: 0;
            margin-top: 2px;
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
            width: 100%;
        }
        .current {
            font-size: 24px;
            line-height: 1;
            @media #{$breakpoint-lg} {
                font-size: 18px;
            }
            @media #{$breakpoint-md} {
                font-size: 14px;
            }
            @media #{$breakpoint-xs} {
                font-size: 18px;
            }
        }
        .option {
            font-size: 20px;
            color: $heading-color;
            width: 100%;
            @media #{$breakpoint-lg} {
                font-size: 16px;
            }
            @media #{$breakpoint-md} {
                font-size: 13px;
            }
            @media #{$breakpoint-xs} {
                font-size: 16px;
            }
        }
    }
}

/* :: 7.0 About Us Area CSS */

.about-thumbnail {
    position: relative;
    z-index: 1;
    &.homepage {
        .first-img {
            position: relative;
            width: 50%;
            margin-left: auto;
            box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.15);
        }
        .second-img {
            position: relative;
            z-index: 1;
            width: 55%;
            margin-top: -50%;
            box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.15);
        }
        .third-img {
            position: relative;
            z-index: 1;
            margin: 0 auto;
            width: 45%;
            margin-top: -30%;
            box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.15);
        }
    }
}

.about-key-text {
    position: relative;
    z-index: 1;
    margin-top: 35px;
    h6 {
        @include display-flex(flex);
        @include align-items(center);
        @include justify-content-center;
        margin-bottom: 20px;
        font-size: 16px;
        span {
            @include flex(0 0 30px);
            max-width: 30px;
            width: 30px;
            margin-right: 15px;
            height: 30px;
            text-align: center;
            font-size: 12px;
            background-color: $pri-color;
            border-radius: 50%;
            line-height: 30px;
            color: $white-color;
        }
    }
}

/* :: 8.0 Pool Area CSS */

.pool-content {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 70px 50px;
    border: 2px solid $pri-color;
    background-color: rgba(0, 0, 0, 0.63);
    @media #{$breakpoint-xs} {
        padding: 50px 20px;
    }
    .pool-feature {
        position: relative;
        z-index: 1;
        text-align: center;
        @media #{$breakpoint-xs} {
            margin-top: 30px;
        }
        i {
            font-size: 70px;
            color: $pri-color;
            margin-bottom: 15px;
            display: block;
        }
        p {
            color: $white-color;
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}

/* :: 9.0 Rooms Area CSS */

.single-rooms-area {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 515px;
    margin-bottom: 100px;
    @include trans-duration(500ms);
    .bg-thumbnail {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    }
    .price-from {
        position: absolute;
        z-index: 20;
        top: -19px;
        left: 50%;
        @include transform(translateX(-50%));
        padding: 10px 15px;
        background-color: #181818;
        border: 2px solid $pri-color;
        display: inline-block;
        margin-bottom: 0;
        line-height: 1;
        color: $white-color;
        font-size: 14px;
    }
    .rooms-text {
        position: absolute;
        z-index: 30;
        background-color: rgba(0, 0, 0, 0.63);
        padding: 30px 30px 40px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        width: calc(100% - 40px);
        border: 2px solid $pri-color;
        text-align: center;
        @include trans-duration(500ms);
        .line {
            width: 90px;
            height: 2px;
            background-color: $pri-color;
            display: block;
            margin: 0 auto 20px;
        }
        h4 {
            color: $white-color;
            @media #{$breakpoint-xs} {
                font-size: 20px;
            }
        }
        p {
            color: $white-color;
            margin-bottom: 0;
        }
    }
    .book-room-btn {
        position: absolute;
        bottom: -15px;
        left: 50%;
        @include transform(translateX(-50%));
        z-index: 50;
    }
    &:hover,
    &:focus {
        .rooms-text {
            background-color: #000;
        }
    }
}

.pagination-area {
    position: relative;
    z-index: 1;
    text-align: center;
    .pagination {
        @include justify-content-center;
        .page-item {
            .page-link {
                border: 2px solid transparent;
                background-color: transparent;
                display: block;
                padding: 5px;
                margin-right: 10px;
                color: #181818;
                font-size: 14px;
                &:hover,
                &:focus {
                    color: $white-color;
                    background-color: #616161;
                    border: 2px solid $pri-color;
                    box-shadow: none;
                }
            }
            &:first-child .page-link {
                margin-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &:last-child .page-link {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &.active {
                .page-link {
                    color: $white-color;
                    background-color: #616161;
                    border: 2px solid $pri-color;
                }
            }
        }
    }
}

/* :: 10.0 Services Area CSS */

.services-intro {
    position: relative;
    z-index: 1;
    .single-cool-fact {
        width: 100%;
    }
}

.core-features-area {
    position: relative;
    z-index: 10;
    .single-core-feature {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 514px;
        .bg-thumbnail {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 5;
        }
        .feature-content {
            width: 70%;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 50;
            background-color: rgba(255, 255, 255, 0.85);
            border: 2px solid $pri-color;
            padding: 30px;
            @include transform(translate(-50%,
            -50%));
            @media #{$breakpoint-xs} {
                width: 85%;
            }
            i {
                font-size: 60px;
                color: $pri-color;
                margin-bottom: 30px;
                display: block;
            }
            h3 {
                font-size: 30px;
                @media #{$breakpoint-lg} {
                    font-size: 24px;
                }
                @media #{$breakpoint-md} {
                    font-size: 24px;
                }
                @media #{$breakpoint-xs} {
                    font-size: 24px;
                }
            }
            p {
                margin-bottom: 0;
            }
        }
    }
}

.single-service-area {
    position: relative;
    z-index: 1;
    i {
        display: block;
        margin-bottom: 30px;
        color: $pri-color;
        font-size: 60px;
    }
    h4 {
        font-size: 30px;
        margin-bottom: 25px;
        @include trans-duration(500ms);
        @media #{$breakpoint-xs} {
            font-size: 24px;
        }
    }
    p {
        margin-bottom: 0;
    }
}

.our-milestones {
    position: relative;
    z-index: 1;
    .single-cool-fact {
        background-color: rgba(0, 0, 0, 0.63);
    }
}

/* :: 11.0 Our Hotel Area CSS */

.single-hotel-info {
    position: relative;
    z-index: 1;
    .hotel-info-text {
        position: relative;
        z-index: 1;
        margin-bottom: 45px;
        h6 {
            @include display-flex(flex);
            @include align-items(center);
            margin-bottom: 15px;
            font-size: 16px;
            span {
                @include flex(0 0 30px);
                max-width: 30px;
                width: 30px;
                margin-right: 15px;
                height: 30px;
                text-align: center;
                font-size: 12px;
                background-color: $pri-color;
                border-radius: 50%;
                line-height: 30px;
                color: $white-color;
            }
        }
    }
}

/* :: 12.0 Testimonial Area CSS */

.testimonial-content {
    position: relative;
    z-index: 1;
    border: 2px solid $pri-color;
    background-color: $white-color;
    padding: 100px;
    width: 100%;
    @media #{$breakpoint-xs} {
        padding: 30px;
    }
    .single-testimonial {
        position: relative;
        z-index: 1;
        text-align: center;
        p {
            font-size: 16px;
            color: $text-color;
            font-style: italic;
            @media #{$breakpoint-xs} {
                font-size: 14px;
            }
        }
        h6 {
            margin-top: 55px;
            margin-bottom: 60px;
            span {
                color: $pri-color;
            }
            @media #{$breakpoint-xs} {
                margin-top: 25px;
                margin-bottom: 30px;
            }
        }
        img {
            width: auto !important;
            margin: 0 auto;
        }
    }
}

/* :: 13.0 Footer Area CSS */


/* :: 14.0 Breadcumb Area CSS */

.breadcumb-area {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 475px;
    @media #{$breakpoint-xs} {
        height: 340px;
    }
    .bradcumbContent {
        margin-top: 70px;
        @media #{$breakpoint-xs} {
            margin-top: 0;
        }
        h2 {
            font-size: 48px;
            color: $white-color;
            margin-bottom: 0;
            padding: 5px 30px;
            border: 2px solid $pri-color;
            background-color: rgba(0, 0, 0, 0.63);
            @media #{$breakpoint-md} {
                font-size: 36px;
            }
            @media #{$breakpoint-xs} {
                font-size: 30px;
            }
        }
    }
}

/* :: 15.0 Blog Area CSS */

.single-blog-post {
    position: relative;
    z-index: 10;
    .blog-post-thumb {
        position: relative;
        z-index: 1;
    }
    .post-content {
        position: relative;
        z-index: 1;
        width: 80%;
        border: 2px solid $pri-color;
        padding: 50px 40px 30px;
        left: 10%;
        text-align: center;
        margin-top: -145px;
        background-color: $white-color;
        @media #{$breakpoint-xs} {
            width: 90%;
            left: 5%;
            margin-top: -25px;
            padding: 40px 30px 20px;
        }
        .post-date {
            position: absolute;
            left: 50%;
            top: -26.5px;
            @include transform(translateX(-50%));
        }
        .post-title {
            font-size: 30px;
            display: block;
            margin-bottom: 10px;
            &:hover,
            &:focus {
                color: $hover-color;
            }
            @media #{$breakpoint-xs} {
                font-size: 20px;
                margin-bottom: 15px;
            }
        }
        .post-meta {
            position: relative;
            z-index: 1;
            margin-bottom: 30px;
            a {
                font-size: 12px;
                color: $heading-color;
                position: relative;
                z-index: 2;
                &:first-child {
                    margin-right: 30px;
                    &::after {
                        position: absolute;
                        top: -2px;
                        right: -18px;
                        content: '|';
                        z-index: 10;
                    }
                }
            }
        }
    }
}

/* :: 16.0 Contact Area CSS */

.contact-area {
    position: relative;
    z-index: 1;
    .home-map-area {
        @include flex(0 0 50%);
        max-width: 50%;
        width: 50%;
        height: 685px;
        @media #{$breakpoint-xs} {
            @include flex(0 0 100%);
            max-width: 100%;
            width: 100%;
            height: 450px;
        }
        iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
    .contact-info {
        @include flex(0 0 50%);
        max-width: 50%;
        width: 50%;
        padding: 0 8%;
        @media #{$breakpoint-xs} {
            @include flex(0 0 100%);
            max-width: 100%;
            width: 100%;
            padding: 50px 30px;
        }
        h4,
        h5 {
            margin-bottom: 15px;
            @media #{$breakpoint-md} {
                font-size: 16px;
            }
            @media #{$breakpoint-xs} {
                font-size: 16px;
            }
        }
        .social-info {
            a {
                display: inline-block;
                margin-right: 15px;
                &:hover,
                &:focus {
                    color: $hover-color;
                }
            }
        }
    }
}

.single-contact-information {
    position: relative;
    z-index: 1;
    .section-text {
        position: relative;
        z-index: 1;
        margin-bottom: 40px;
        h3 {
            font-weight: 400;
            font-size: 30px;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
        }
    }
    .contact-content {
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        p {
            font-size: 15px;
            color: $text-color;
            margin-bottom: 0;
        }
        p:first-child {
            font-size: 18px;
            color: $pri-color;
            margin-bottom: 0;
            @include flex(0 0 90px);
            max-width: 90px;
            width: 90px;
        }
    }
}

.map-area {
    position: relative;
    z-index: 2;
    iframe {
        width: 100%;
        height: 515px;
        border: none;
        margin-bottom: 0;
        @media #{$breakpoint-xs} {
            height: 300px;
        }
    }
}

.contact-form-area {
    position: relative;
    z-index: 1;
    form {
        position: relative;
        z-index: 1;
        input,
        textarea {
            @include trans-duration(500ms);
            border: none;
            border-bottom: 2px solid #c9c9c9;
            margin-bottom: 30px;
            font-size: 12px;
            font-style: italic;
            color: $text-color;
            border-radius: 0;
            width: 100%;
            height: 52px;
            &:focus {
                border-color: $pri-color;
                box-shadow: none;
            }
        }
        textarea {
            height: 180px;
        }
    }
}

/* :: 17.0 Skills Area CSS */

.single-skils-area {
    position: relative;
    z-index: 1;
    text-align: center;
    .circle {
        position: relative;
        z-index: 10;
        margin-bottom: 15px;
    }
    .skills-text {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        @include transform(translate(-50%,
        -50%));
        span {
            font-size: 48px;
            color: #232323;
        }
        p {
            margin-bottom: 0;
            line-height: 1;
            font-size: 14px;
            color: #232323;
        }
    }
}

/* :: 18.0 Elements Area CSS */

.elements-title {
    h2 {
        font-size: 48px;
        margin-bottom: 20px;
        @media #{$breakpoint-xs} {
            font-size: 24px;
        }
    }
}

.single-icon {
    position: relative;
    z-index: 1;
    padding: 50px 30px;
    border: 2px solid $pri-color;
    text-align: center;
    i {
        color: $pri-color;
        font-size: 70px;
        display: block;
        margin-bottom: 30px;
    }
    span {
        font-size: 16px;
    }
}

/* :: 18.1.0 Single Cool Facts CSS */

.single-cool-fact {
    position: relative;
    z-index: 1;
    width: 200px;
    padding: 35px 15px;
    border: 2px solid $pri-color;
    text-align: center;
    background-color: $heading-color;
    margin: 0 auto;
    @include trans-duration(500ms);
    .scf-text {
        i {
            @include trans-duration(500ms);
            display: block;
            font-size: 70px;
            margin-bottom: 10px;
            color: $pri-color;
        }
        h2 {
            font-size: 48px;
            margin-bottom: 5px;
            color: $white-color;
            @media #{$breakpoint-lg} {
                font-size: 48px;
            }
            @media #{$breakpoint-md} {
                font-size: 36px;
            }
            @media #{$breakpoint-xs} {
                font-size: 30px;
            }
        }
        p {
            font-size: 14px;
            margin-bottom: 0;
            color: $white-color;
            line-height: 1;
        }
    }
    &:hover,
    &:focus {
        background-color: $pri-color;
        .scf-text i {
            color: $white-color;
        }
    }
}

/* :: 18.2.0 Accordians Area CSS */

.single-accordion {
    &.panel {
        background-color: $white-color;
        border: 0 solid transparent;
        border-radius: 4px;
        box-shadow: 0 0 0 transparent;
        margin-bottom: 15px;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    h6 {
        margin-bottom: 0;
        a {
            background-color: $pri-color;
            border-radius: 0;
            border: 2px solid $pri-color;
            color: $white-color;
            display: block;
            margin: 0;
            padding: 20px 70px 20px 30px;
            position: relative;
            font-size: 14px;
            text-transform: capitalize;
            span {
                background: transparent;
                @include trans-duration(500ms);
                font-size: 10px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                width: 58px;
                height: 100%;
                color: $white-color;
                line-height: 58px;
                &.accor-open {
                    opacity: 0;
                }
            }
            &.collapsed {
                @include trans-duration(500ms);
                background-color: $white-color;
                color: $text-color;
                border: 2px solid $pri-color;
                span {
                    color: $text-color;
                    &.accor-close {
                        opacity: 0;
                    }
                    &.accor-open {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .accordion-content {
        border-top: 0 solid transparent;
        box-shadow: none;
        padding: 30px 15px 5px;
        p {
            margin-bottom: 0;
        }
    }
}

/* :: 18.3.0 Tabs Area CSS */

.palatin-tabs-content {
    position: relative;
    z-index: 1;
    .nav-tabs {
        border-bottom: none;
        margin-bottom: 30px;
        .nav-link {
            background-color: $white-color;
            border: 2px solid $pri-color;
            padding: 0 20px;
            height: 58px;
            line-height: 58px;
            color: $text-color;
            margin: 0 2px;
            border-radius: 0;
            @media #{$breakpoint-lg} {
                padding: 0 15px;
            }
            @media #{$breakpoint-xs} {
                padding: 0 15px;
                font-size: 12px;
                margin-bottom: 15px;
            }
            @media #{$breakpoint-xs-landscape} {
                padding: 0 15px;
            }
            &.active {
                background-color: $pri-color;
                color: $white-color;
            }
        }
    }
    .palatin-tab-text {
        p {
            margin-bottom: 0;
        }
    }
    .tab-content {
        h6 {
            font-size: 18px;
        }
    }
}



/* logo rodando */
a.conjunto {
    /*top: 2px;*/
    /*position: relative;*/
    /*width: 239px;;*/
    /*height: 90px;*/
}

.bloco {
    width: 150px;
    top: -55px;
    position: relative;
    left: 20px;
}

.bloco div.logo, .bloco img.logo {
    position: absolute;
    left: -10px;
}

.bloco img.logo-2 {
    position: absolute;
    left: 123px;
    animation: rodar 4s linear infinite;
    //width: 30%;
}

.bloco img.grad {
    position: absolute;
    left: 123px;
    //width: 30%;
}

.bloco img.vento {
    position: absolute;
    left: 141px;
    top: -4px;
    //width: 30%;
}

@keyframes rodar {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.conjunto:hover > .bloco img.logo-2 {
    animation: contra 1.5s linear infinite;
}

@keyframes contra {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.logo-texto {
    position: absolute;
    left: -10px;
}

.logo-texto .logo-titulo
{
    color: silver;
    font-size: 1.8em;
    text-align: center;
}

.logo-texto .logo-subtitulo
{
    font-size: 0.7em;
    color: whitesmoke;
    text-align: center;
    text-shadow: 0px 0px 1px #920d28;
    letter-spacing: 2px;
}

.logo-animado {
    position: relative;
    left: -35px;
    width: 36%;
    top: 10px;
}


// Botão Nav bar
.header-area .palatin-main-menu .classy-navbar .classynav ul li.active a {
    color: #ffffff;
    background-color: #860917;
}

// menu submenu popup
.breakpoint-off .classynav ul li .dropdown {
    width: max-content;
    background-color: #ffffff;
    height: auto;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    opacity: 0;
    padding: 10px 0;
}

.classynav ul.dropdown li a {
    text-transform: uppercase !important;
}

.header-area .palatin-main-menu .classy-nav-container {
    background-color: rgba(157, 128, 128, 0.22); //#9d808042;
}


#form-icones {
    flex-wrap: nowrap;
}


.palatin-btn:hover, .classynav ul li:hover{
    // background: linear-gradient(355deg, #ccc, #920d28);
    background: repeating-radial-gradient(#920d28, #ccc 400px);

    @media #{$breakpoint-xs} {
        background: none;
    }
}


/*LINKS*/
.menu-link {
    text-decoration: none; /* Remova a decoração de texto padrão do link */
    color:white;
}

.menu-link:hover {
    color:whitesmoke;
}


/* ====== The End ====== */




/**
 * Wordpress API Styles
 */

/* Centrarlizar imagens */
.wp-block-image {

    .aligncenter {
        text-align: center;
        align-items: center;
    }

}

.has-vivid-red-color
{
    color: #8d0000;
}

/**
 * Wordpress classes
 */

.aligncenter {
    text-align: center;
}

h1 {
    font-size: 33px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 23px;
}

h4 {
    font-size: 19px;
}

h5 {
    font-size: 17px;
}

h6 {
    font-size: 15px;
}

a {
  color: #860917;
  text-decoration: revert;

  &:hover {
    color: $hover-color;
    text-decoration: underline;
  }
}

