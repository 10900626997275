/* :: 13.0 Footer Area CSS */

@import "../../../Assets/Scss/theme_color";
@import "../../../Assets/Scss/fonts";
@import "../../../Assets/Scss/mixin";
@import "../../../Assets/Scss/responsive";

.footer-area {
    position: relative;
    z-index: 1;
    background-color: $footer-color;
    padding-bottom: 40px;
    .footer-widget-area {
        p {
            color: #cccccc;
            font-size: 16px;
            margin-bottom: 0;
        }
        .widget-title {
            padding-top: 15px;
            color: $white-color;
            font-weight: 400;
        }
        .subscribe-form {
            position: relative;
            z-index: 1;
            input {
                width: 100%;
                height: 46px;
                background-color: transparent;
                border: 2px solid $pri-color;
                padding: 0 15px;
                color: $white-color;
            }
            button {
                @include trans-duration(500ms);
                width: 100%;
                height: 46px;
                border: none;
                font-size: 16px;
                margin-top: 15px;
                color: $white-color;
                cursor: pointer;
                background-color: $pri-color;
                &:hover,
                &:focus {
                    background-color: $heading-color;
                }
            }
        }
    }
    .copywrite-text {
        position: relative;
        z-index: 1;
        p {
            margin-bottom: 0;
            line-height: 1.5;
            font-size: 12px;
            color: rgba(204, 204, 204, 0.4);
            a {
                font-size: 12px;
                color: rgba(204, 204, 204, 0.4);
                &:hover {
                    color: $white-color;
                }
            }
        }
    }
}


/**
 * Overide Footer Area Css
 */

h2 {
    color: whitesmoke;
}

.social-info a {
    font-size: 25px !important;
    color:white !important;
    margin-right: 15px;
}

.bloco-ouvidoria {
    border: 1px solid rgb(134, 9, 23); padding: 10px; text-align: center;
}

.bloco-ouvidoria .line {
    width: 90px;
    height: 2px;
    background-color: rgb(134, 9, 23);
    display: block;
    margin: 20px auto 20px;
}