// Heading Color
$heading-color: #363636;

// Text Color
$text-color: #7d7d7d;

// Default Background
$bg-default: #cb8670;

// Gray Background
$bg-gray: #f3f3f3;

// Primary Color
$pri-color: rgb(134, 9, 23);

// White Color
$white-color: #ffffff;

// Dark Color
$dark-color: #141414;

// Border Color
$border-color: #eaeaea;

// Hover Color
$hover-color: #cb8670;

// Footer Color
$footer-color: #363636;

// Secondary Color
$secondary-color: #838383;