.ReactModalPortal {
  position: relative;
  z-index: 1002;


  iframe {
    width: 100%;
    height: 100% !important;
  }

  .ReactModal__Content {
    padding: 0px !important;
    overflow: inherit !important;
  }

  .btn-close {

    position: absolute;
    top: 80px;
    right: 10px;
    background-color: white;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000000;

  }

}