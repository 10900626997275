@mixin trans-duration($time) {
    -webkit-transition-duration: $time;
    transition-duration: $time;
}

@mixin transition($value) {
    -webkit-transition: $value;
    transition: $value;
}

@mixin transform($value) {
    -webkit-transform: $value;
    transform: $value;
}

@mixin animation($value) {
    -webkit-animation: $value;
    animation: $value;
}

@mixin flex-direction($value) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin align-items($value) {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
    -ms-grid-row-align: $value;
    align-items: $value;
}

@mixin display-flex($value) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: $value;
}

@mixin justify-content-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@mixin justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@mixin flex($value) {
    -webkit-box-flex: 0;
    -ms-flex: $value;
    flex: $value;
}

@mixin flex-wrap($value) {
    -ms-flex-wrap: $value;
    flex-wrap: $value;
}

@mixin bg-gradients {
    background: #cc1573;
    background: -webkit-linear-gradient(to right, #cc1573, #5722a9);
    background: linear-gradient(to right, #cc1573, #5722a9);
}