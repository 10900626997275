section.breadcumb-area {
}

.rooms-area .single-rooms-area .rooms-text {
  min-height: 313.70px;
}

.title {
  min-height: 3em; /* Ajuste este valor de acordo com o tamanho da fonte e o espaçamento desejado */
  line-height: 1.5em; /* Ajuste este valor de acordo com o tamanho da fonte e o espaçamento desejado */
}