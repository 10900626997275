section.breadcumb-area {

  // Cria opacidade apenas no imagem de fundo da section
  position: relative;

  &::before {
    //content: '';
    //position: absolute;
    //top: 0;
    //left: 0;
    //width: 100%;
    //height: 100%;
  }

  // O Filhos quebram a herança a partir daqui
  > .bradcumbContent {
    position: relative;
    z-index: 1;
    // Estilos para o conteúdo dentro da section.breadcumb-area
  }

  > .header-image-background {
    filter: sepia(20%);
    //opacity: 0.85;
    background-position-y: 38%;
  }


}

p {
  font-size: 17px;
}