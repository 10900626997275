.allnews .palatin-blog-posts {
  margin-top: -40px;
}


.about-thumbnail.homepage .wow{
  //width: 30%;
}


.post-thumb {
  position: relative;
  overflow: hidden;
}

// camada play dos videos

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.play-icon {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid white;
  transform: rotate(90deg);
  cursor: pointer;
}

.post-thumb:hover .overlay {
  opacity: 1;
}


/* Media query para telas maiores que 767px */
@media (min-width: 767px) {
  .allnews .palatin-blog-posts {
    margin-top: 90px;
  }

  .single-blog-post {
    padding-top: 135px;
  }

}


/* Media query para telas menores que 767px */
@media (max-width: 766px) {
  .allnews .single-blog-post {
    margin-top: 100px;
  }
}











